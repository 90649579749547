import { FEE_ADVANCED as categories } from 'constants/fee';
import { useState } from 'react';
import {
  Heading,
  List,
  ListItem,
  Text,
  Box,
  Circle,
  Button,
  Flex,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Stack,
  useMediaQuery
} from '@chakra-ui/react';
import { responsive } from 'contexts/responsive';
import getNumberComma from 'helpers/getNumberComma';
import { useForm, useWatch } from 'react-hook-form';
import SVG from 'components/SVG';
import AdvancedModal from 'components/AdvancedModal';
import { usePlausible } from 'next-plausible';

const categoryAffix = {
  monthly: ' /每月每項',
  byModule: ' /單一模組每項'
};

export const PricingColumn = ({
  title,
  name,
  type,
  addons,
  setOpen,
  pricing,
  allowMultiple,
  trackerType = 'extra_click',
  ...props
}) => {
  const plausible = usePlausible();
  // const [isWide] = useMediaQuery('(min-width: 768px)');
  // console.log(isWide)
  return (
    <Box {...props}>
      <Heading
        as="h3"
        mb={[3, null, null, 6]}
        fontSize={['2xl', null, null, '4xl']}
      >
        <SVG
          viewBox="0 0 48 48"
          w={[9, null, null, 12]}
          h={[9, null, null, 12]}
          me={1}
          verticalAlign="text-top"
        >
          <use xlinkHref="/sprites/home.svg#iconLikeExclaim" />
        </SVG>
        {title}
      </Heading>
      <Accordion allowToggle allowMultiple={allowMultiple}>
        <Stack spacing={responsive(4, null, 6)}>
          {addons.map(({ title, details, name }, index) => {
            const filterDetail = type
              ? details.filter(
                  (detail) => !detail.include || detail.include.includes(type)
                )
              : details;
            return (
              filterDetail.length > 0 && (
                <AccordionItem bg="black" border="none" key={index}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        onClick={() => {
                          if (typeof window.gtag === 'function') {
                            window.gtag('event', trackerType, {
                              button_name: `advanced_${name}`
                            });
                          }

                          plausible('clickButton', {
                            props: {
                              buttonName: `advanced_${name}`
                            }
                          });
                        }}
                        px={responsive('0.625rem', '2rem')}
                        py={[3, null, 2]}
                        bg="black"
                        color="white"
                      >
                        <Text
                          as="span"
                          flex="1"
                          textAlign="left"
                          mr={responsive('0.75rem', '1rem')}
                        >
                          <Text
                            as="strong"
                            me={1}
                            fontSize={['1rem', '1.3125rem']}
                            lineHeight={1}
                          >
                            {title}
                          </Text>
                          {!allowMultiple && (
                            <Text
                              as="span"
                              me={2}
                              fontSize={['1rem', '1.3125rem']}
                              fontWeight="bold"
                              lineHeight={1}
                              textDecor={!pricing ? null : 'line-through'}
                            >
                              +$
                              {getNumberComma(
                                !pricing
                                  ? details[0].price.monthly
                                  : details[0].price.byModule.original
                              )}
                            </Text>
                          )}
                          <SVG
                            viewBox="0 0 20 20"
                            display={!pricing ? 'none' : 'inline-block'}
                            width={5}
                            height={5}
                            me={2}
                            verticalAlign="sub"
                            alt=""
                          >
                            <use xlinkHref="/sprites/home.svg#smallArrow" />
                          </SVG>
                          {!allowMultiple && (
                            <>
                              <Text
                                as="span"
                                display={!pricing ? 'none' : null}
                                fontSize={['1rem', '1.3125rem']}
                                fontWeight="bold"
                              >
                                $
                                {getNumberComma(
                                  !pricing
                                    ? details[0].price.monthly
                                    : details[0].price.byModule.discount
                                )}
                              </Text>
                              <Text
                                as="span"
                                fontSize={['md', 'lg']}
                                lineHeight={1}
                                verticalAlign="baseline"
                              >
                                {
                                  categoryAffix[
                                    !pricing ? 'monthly' : 'byModule'
                                  ]
                                }
                              </Text>
                            </>
                          )}
                        </Text>
                        <Circle
                          as="span"
                          size={responsive('1.25rem', '2rem')}
                          transform={
                            isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'
                          }
                          border="1px solid white"
                        >
                          <SVG viewBox="0 0 50 50" w={5} h={5} alt="">
                            <use xlinkHref="/sprites/home.svg#mediumArrow" />
                          </SVG>
                        </Circle>
                      </AccordionButton>
                      <AccordionPanel p="1px">
                        <List
                          borderBottomRadius="1.25rem"
                          overflow="hidden"
                          bg="white"
                        >
                          {filterDetail.map((detail, index) => (
                            <ListItem
                              py={[2, 3]}
                              textAlign="center"
                              whiteSpace="pre-wrap"
                              borderBottom="1px solid black"
                              key={`pricing-${detail.name}-details-${index}`}
                              _last={{
                                borderBottom: 'none'
                              }}
                              _hover={{
                                backgroundColor: 'gray.200'
                              }}
                              onClick={() => {
                                setOpen(detail.name);
                                if (typeof window.gtag === 'function') {
                                  window.gtag('event', trackerType, {
                                    button_name: `${name}_${detail.name}`
                                  });
                                }

                                plausible('clickButton', {
                                  props: {
                                    buttonName: `${name}_${detail.name}`
                                  }
                                });
                              }}
                              cursor="pointer"
                            >
                              <Text
                                as="strong"
                                display="block"
                                mb={2}
                                fontSize={responsive('1rem', '1.5em')}
                                fontWeight={400}
                              >
                                {detail.title}
                              </Text>
                              {!!detail.subtitle && (
                                <Text as="span">{detail.subtitle}</Text>
                              )}
                            </ListItem>
                          ))}
                        </List>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              )
            );
          })}
        </Stack>
      </Accordion>
    </Box>
  );
};

const FeeAdvanced = () => {
  const messages = {
    title: '超好用進階功能',
    labels: {
      monthly: '月費計價',
      byModule: '模組計價'
    }
  };

  const { register, control, setValue } = useForm();

  const pricing = useWatch({
    control,
    name: 'pricing'
  });

  const Toggle = ({ labels }) => {
    return (
      <Flex
        gap={3}
        justifyContent="center"
        alignItems="center"
        mx="auto"
        mb={0}
      >
        <Box>
          <Button
            onClick={() => setValue('pricing', false)}
            variant="unstyled"
            flex="1 1 50%"
            fontSize="lg"
            pointerEvents={pricing ? null : 'none'}
          >
            {labels.monthly}
          </Button>
        </Box>
        <Box height="18px">
          <input
            id="toggleSwitch"
            type="checkbox"
            {...register('pricing')}
            checked={pricing}
            hidden
          />
          <Box
            as="label"
            htmlFor="toggleSwitch"
            display="block"
            width="38px"
            height="18px"
            position="relative"
            backgroundColor={pricing ? 'accent.orangered' : 'accent.aqua'}
            border="1px solid black"
            borderRadius="full"
            transition="background-color 0.25s ease-out"
            _after={{
              display: 'block',
              content: '""',
              width: '18px',
              height: '18px',
              position: 'absolute',
              top: '-1px',
              right: '-1px',
              left: '-1px',
              marginInlineStart: pricing ? 'auto' : 0,
              backgroundColor: 'white',
              border: '1px solid black',
              borderRadius: 'full',
              transition: 'margin-inline-start 0.25s ease-out'
            }}
          />
        </Box>
        <Box>
          <Button
            onClick={() => setValue('pricing', true)}
            variant="unstyled"
            flex="1 1 50%"
            fontSize="lg"
            pointerEvents={pricing ? 'none' : null}
          >
            {labels.byModule}
          </Button>
        </Box>
      </Flex>
    );
  };

  const [open, setOpen] = useState();

  return (
    <Box>
      <Heading width={[null, '480px']} mx="auto" px={[9, 0]}>
        <SVG viewBox="0 0 448 82" width="full">
          <title>{messages.title}</title>
          <use xlinkHref="/sprites/home.svg#textFeeAdvanced" />
        </SVG>
      </Heading>
      <Toggle labels={messages.labels} />
      <Box
        maxWidth={['640px', null, '800px', null, '992px']}
        mx="auto"
        textAlign={'center'}
        mb={[2, 4]}
      >
        ＊以下價格皆為未稅＊
      </Box>
      <Stack
        direction={['column', null, null, 'row']}
        spacing={[6, 8]}
        width="100%"
        maxWidth={['640px', null, '800px', null, '992px']}
        mx="auto"
        px={[8, null, 20, 0]}
      >
        {categories.map(({ title, name, addons, details }, index) => (
          <PricingColumn
            setOpen={setOpen}
            flex={(null, '1 1 50%')}
            pricing={pricing}
            title={title}
            name={name}
            addons={addons}
            details={details}
            key={`pricing-category-${index}`}
          />
        ))}
      </Stack>
      {open && <AdvancedModal isOpen={open} onClose={() => setOpen(false)} />}
    </Box>
  );
};

export default FeeAdvanced;
