export const PLAN_STEPS = {
  new: ['選擇方案', '確認訂單資訊', '付款完成'],
  repurchase: ['加購方案', '確認訂單資訊', '付款完成']
};

export const CURRENCY = '$';

export const PLANS = {
  monthly: { title: '月費計價' },
  byModule: { title: '模組計價' },
  campaign: { title: '驚喜特惠' }
};

export const MONTH_DAYS = 30;
export const MONTH_MAX = 99;
export const MONTH_BYMODULE = 6;
export const FEE_MONTHLY = 3980;
export const FEE_MODULE = [
  {
    from: 3600,
    to: 18800
  }
];

export const CAMPAIGN = [
  {
    module: 'survey',
    altName: '問卷一頁一題/問卷一頁多題',
    fee: 590,
    due: '2023-06-30',
    promotion: {
      title: '限時優惠只到 2023-06-30！',
      description:
        '本方案僅能發布「問卷一頁一題/問卷一頁多題」，方案包含：5,000 筆填答量/月，並額外贈送支援上傳 GIF 檔案、FB Pixel + GA4 與外連結果頁。'
    }
  }
];

export const FREE = [
  {
    module: 'survey'
  }
];

export const ANSWERS_BY_PLAN = {
  monthly: 10000,
  byModule: 30000,
  sameByModule: 0,
  campaign: 5000,
  free: 1000
};

export const ANSWERS_ADDITION = [
  {
    amount: 0,
    price: 0
  },
  {
    amount: 50000,
    price: 1950
  },
  {
    amount: 100000,
    price: 2600
  },
  {
    amount: 200000,
    price: 3900
  },
  {
    amount: 500000,
    price: 6500
  },
  {
    amount: 1000000,
    price: 10400
  }
];

const advancedPrice = {
  animation: {
    monthly: 195,
    byModule: { original: 585, discount: 527 },
    sameByModule: { original: 585, discount: 527 }
  },
  personalized: {
    monthly: 150,
    byModule: { original: 390, discount: 351 },
    sameByModule: { original: 390, discount: 351 }
  },
  acquisition: {
    monthly: 390,
    byModule: { original: 1300, discount: 1170 },
    sameByModule: { original: 1300, discount: 1170 }
  },
  report: {
    monthly: 650,
    byModule: { original: 2145, discount: 1950 },
    sameByModule: { original: 2145, discount: 1950 }
  }
};

export const FEE_ADVANCED = [
  {
    title: '體驗升級功能',
    name: 'experience',
    addons: [
      {
        title: '動態相關',
        name: 'animation',
        details: [
          {
            name: 'uploadGif',
            title: '支援上傳 GIF 檔案',
            price: {
              ...advancedPrice.animation,
              campaign: 0
            },
            unlock: {
              title: '上傳吸睛的動態圖像',
              description:
                '在模組封面、題目或結果加上動態圖像，除了能建立亮眼的視覺印象之外，更能幫助使用者抓住重點！'
            }
          },
          {
            name: 'backgroundMusic',
            title: '上傳背景音樂',
            price: {
              ...advancedPrice.animation,
              campaign: 195
            },
            unlock: {
              title: '上傳自訂音樂',
              description:
                '在遊戲體驗或題目作答時，透過加入客製的背景音樂，更能讓使用者身歷其境，提升互動體驗！'
            }
          }
        ]
      },
      {
        title: '個人化體驗',
        name: 'personalized',
        details: [
          {
            name: 'sameAsMe',
            title: '結果頁呈現相同結果比率',
            subtitle: '限定用於：心理測驗/多維度測驗',
            include: ['classic', 'multifactor'],
            price: {
              ...advancedPrice.personalized,
              campaign: 150
            },
            unlock: {
              title: '呈現客製化作答結果',
              description:
                '在結果頁加入即時的計算結果，讓使用者更願意分享遊戲或測驗給朋友一起挑戰！'
            }
          },
          {
            name: 'scoreDetails',
            title: '結果頁呈現展開作答題目',
            subtitle: '限定用於：得分挑戰',
            include: ['swiper'],
            price: {
              ...advancedPrice.personalized,
              campaign: 150
            },
            unlock: {
              title: '完整展開作答選項',
              description:
                '挑戰結束後，讓使用者能細細回顧作答的過程正確與否，提升停留時間與分享意願！'
            }
          }
        ]
      }
    ]
  },
  {
    title: '行銷升級功能',
    name: 'marketing',
    addons: [
      {
        title: '精準獲客',
        name: 'acquisition',
        details: [
          {
            name: 'mailingList',
            title: '留資料/蒐集名單',
            price: {
              ...advancedPrice.acquisition,
              campaign: 390
            },
            unlock: {
              title: '一站就能完成收取資料',
              description:
                '只要設定好按鈕，無需讓使用者外連表單，就能完整收取使用者填答紀錄與資料不分散！'
            }
          },
          {
            name: 'redirectResult',
            title: '外連結果頁',
            subtitle: '限定用於：問卷一頁一題/一頁多題/客製圖產生器',
            include: ['survey', 'canvas'],
            price: {
              ...advancedPrice.acquisition,
              campaign: 0
            },
            unlock: {
              title: '提升使用者轉換機率',
              description:
                '事先將完成填答的按鈕設定好一鍵外連至 Chatbot 或任何網站，就能大幅提升使用者的轉換機會！'
            }
          }
        ]
      },
      {
        title: '投放助攻',
        name: 'report',
        details: [
          {
            name: 'tagManager',
            title: 'FB Pixel + GA4',
            price: {
              ...advancedPrice.report,
              campaign: 0
            },
            unlock: {
              title: '輸入代碼自動追蹤數據',
              description:
                '一鍵輸入代碼，就能自動幫你綁定，即可在對應後台看到使用者行為數據！'
            }
          }
          // {
          //   name: 'inframe',
          //   title: '將測驗嵌入外部網頁',
          //   unlock: {
          //     title: '善用嵌入聚集流量',
          //     description:
          //       '無需從零開始設定網頁，將製作好的互動模組透過一行語法即可嵌入你的品牌/活動網頁中！'
          //   }
          // },
        ]
      }
    ]
  }
];

export const FEE_YEARLY = 36000;
