import React, { useMemo } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
  Box,
  Text,
  Stack
} from '@chakra-ui/react';
import { responsive } from 'contexts/responsive';
import Button from './Button';
import SVG from './SVG';
import RatioImage from './RatioImage';
import Link from 'next/link';
import advanced from 'constants/advanced';

const AdvancedModal = ({ isOpen, onClose }) => {
  const data = useMemo(() => advanced[isOpen], [isOpen]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        width={responsive('91.5%', '100%')}
        maxWidth={'41em'}
        bg="#52D5AE"
        borderRadius={0}
        border="1px solid"
        p={responsive('1em', '1.375em')}
      >
        <ModalCloseButton
          borderRadius={0}
          bg="white"
          border="1px solid"
          fontSize="1.5em"
          width={responsive('2.5rem', '3.125rem')}
          height={responsive('2.5rem', '3.125rem')}
          top={responsive('-6px', '-8px')}
          right={responsive('-6px', '-8px')}
          zIndex={5}
        />
        <ModalBody bg="black" p="1px">
          <Flex
            flexDir={responsive('column', 'row')}
            bg="white"
            borderRadius={'0 0 1.25em 0'}
            alignItems={'center'}
          >
            <Box
              borderRight={responsive('none', '1px solid')}
              width={responsive('100%', '51.2%')}
            >
              <RatioImage src={data?.cover} ratio={1} />
            </Box>
            <Box
              py="1em"
              px="1.5em"
              width={responsive('100%', '48.8%')}
              borderTop={responsive('1px solid', 'none')}
            >
              <Box
                p="0.25em"
                fontWeight={'bold'}
                display={'inline-block'}
                bg="#FCC65A"
                border={'1px solid'}
              >
                #{data.hash}
              </Box>
              <Stack spacing={2} pt="2px">
                <Text
                  fontWeight={'bold'}
                  fontSize={responsive('1.375em', '1.5em')}
                >
                  {data.title}
                </Text>
                <Text fontWeight={500} fontSize={responsive('0.875em', '1em')}>
                  {data.description}
                </Text>
                <Box
                  as={Link}
                  // href={`/plans?addon=${data.addonName}&detail=${data.name}`}
                  href={{
                    pathname: '/plans',
                    query: {
                      addon: data.addonName,
                      detail: data.name
                    }
                  }}
                  width="100%"
                  pt="0.5em"
                >
                  <Button
                    width="100%"
                    variant="outline"
                    fontSize={'1.125em'}
                    border="1px solid"
                    fontWeight={500}
                    _hover={{
                      color: 'white',
                      backgroundColor: 'black',
                      borderColor: 'black'
                    }}
                  >
                    立即解鎖
                    <SVG w={5} h={5} ms={1} viewBox="0 0 50 50" alt="">
                      <use xlinkHref="/sprites/home.svg#mediumArrow" />
                    </SVG>
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AdvancedModal;
