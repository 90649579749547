import React from 'react';
import {
  Box,
  Center,
  Flex,
  HStack,
  LinkOverlay,
  Heading,
  Text
} from '@chakra-ui/react';
import { useHover } from 'react-use';

import Carousel from '../components/Carousel';
import RatioImage from '../components/RatioImage';
import quizModules from 'quiz-modules';

import law from './images/law.png';
import law_logo from './images/law_logo.png';
import soul from './images/soul.jpg';
import giloo from './images/giloo.png';
import justfont from './images/justfont.png';
import jf_logo from './images/jf_logo.png';
import no_funny from './images/no_funny.png';
import laugh from './images/laugh.png';
import taiwan_bar from './images/taiwan_bar.png';
import taiwan_bar_logo from './images/taiwan_bar_logo.png';
import super_quiz from './images/super_quiz.png';
import super_quiz_logo from './images/super_quiz_logo.png';
import godoflove from './images/godoflove.png';
import godoflove_logo from './images/godoflove_logo.png';
import relab from './images/relab.png';
import relab_logo from './images/relab_logo.png';
import bar from './images/bar.png';
import bar_logo from './images/bar_logo.png';
import t1 from './images/t1.webp';
import t1_logo from './images/t1_logo.png';
// import flower from './images/flower.jpg';
// import flower_logo from './images/flower_logo.png';
import bonne_chance from './images/bonne-chance.png';
import bonne_chance_logo from './images/bonne_chance_logo.png';
import asperger from './images/asperger.png';
// import nius_logo from './images/nius_logo.png';
import happyRabbitYou from './images/happyRabbitYou.jpg';
import re_coral from './images/re_coral.png';
import portfolio from './images/portfolio.webp';
import doumiao from './images/doumiao.webp';
import doumiao_logo from './images/doumiao_logo.png';
import play from './images/play.svg';
import medal from './images/medal.png';
// import arrow_next from './images/arrow_next.png';
import { responsive } from 'contexts/responsive';
import TitleText from 'components/TitleText';
import Button from 'components/Button';

const fallbackNames = {
  runway: '跑道閃避',
  multiSurvey: '問卷一頁多題'
}

export const games = [
  {
    title: 'RE:LAB',
    desc: '',
    src: re_coral,
    logo: '/android-chrome-192x192.png',
    href: 'https://ooopenlab.cc/quiz/We9mHMexRsTGfDKg2X9i',
    features: ['視覺靈活度高', '友善海洋環境'],
    type: 'multiSurvey'
  },
  {
    title: 'RE:LAB',
    desc: '',
    src: portfolio,
    logo: '/android-chrome-192x192.png',
    href: 'https://ooopenlab.cc/quiz/8hglJZJYhfuRv5vI5sLL',
    features: ['呼應品牌識別', '質感黑色運用'],
    type: 'survey'
  },
  {
    title: '豆苗先生',
    desc: '',
    src: doumiao,
    logo: doumiao_logo,
    href: 'https://ooopenlab.cc/quiz/oklIwcl8ILpS1UqYO7Gb',
    features: ['打造限定角色', '客製大頭貼'],
    type: 'canvas'
  },
  {
    title: '超測驗',
    desc: '',
    src: asperger,
    logo: super_quiz_logo,
    href: 'https://ooopenlab.cc/quiz/WKDXgoHwVLn8h1gsYrKc',
    features: ['量表視覺化', '專業理論基礎'],
    type: 'measure'
  },
  {
    title: '超測驗',
    desc: '',
    src: super_quiz,
    logo: super_quiz_logo,
    href: 'https://ooopenlab.cc/quiz/Nkn1tVyDkOh2dtsOP33Y',
    features: ['專業理論基礎', '直覺作答'],
    type: 'classic'
  },
  // {
  //   title: '你的在線月老',
  //   desc: '',
  //   src: godoflove,
  //   logo: godoflove_logo,
  //   href: 'https://ooopenlab.cc/quiz/HPBMfo8oJL8J7ZY0xvB0',
  //   features: ['熱門分享', '反應測試'],
  //   type: 'runway'
  // },
  // {
  //   title: 'RE:LAB',
  //   desc: '',
  //   src: relab,
  //   logo: relab_logo,
  //   href: 'https://ooopenlab.cc/quiz/MZDZfxurmH00vpSwRjSm',
  //   features: ['遊戲化體驗', '超難過關'],
  //   type: 'runway'
  // },
  {
    title: '驚釀小酒館',
    desc: '',
    src: bar,
    logo: bar_logo,
    href: 'https://ooopenlab.cc/quiz/F7x3r4jH6tWyJM50TPQm',
    features: ['完成率高', '人生調酒測驗'],
    type: 'classic'
  },
  {
    title: '臺灣吧',
    desc: '',
    src: taiwan_bar,
    logo: taiwan_bar_logo,
    href: 'https://ooopenlab.cc/quiz/MIqzfDpb2B7fdP7Rxz4y',
    features: ['耐玩好互動', '客製化 IP'],
    type: 'canvas'
  },
  // {
  //   title: 'T1 league',
  //   desc: '',
  //   src: t1,
  //   logo: t1_logo,
  //   href: '',
  //   features: ['跨界合作', '情境式調查'],
  //   type: 'classic'
  // },
  {
    title: '不好笑',
    desc: '',
    src: laugh,
    logo: no_funny,
    href: 'https://ooopenlab.cc/quiz/dpoikL1Ng5rhA3SRXste',
    features: ['有笑有推', '擴散程度高'],
    type: 'swiper'
  },
  {
    title: '超開放實驗室',
    desc: 'Happy 兔 You',
    src: happyRabbitYou,
    logo: '/android-chrome-192x192.png',
    href: 'https://ooopenlab.cc/quiz/DGHd7HIQziCcBD1QnNCw',
    features: ['客製程度高', '兔年暖心攻勢'],
    type: 'canvas'
  },
  {
    title: '法律白話文運動',
    desc: '專注打造台灣法律文化的垂直媒體。從法律認識議題，從議題反思法律。',
    src: law,
    coverBg: '#cb182e',
    logo: law_logo,
    href: 'https://ooopenlab.cc/quiz/7IG8CVFAcsgRJBKo4OmL',
    features: ['長尾效應', '法律小天才'],
    type: 'swiper'
  },
  {
    title: 'Giloo 紀實影音',
    desc: '台灣唯一以影展及議題為導向的影音平台，致力打造影迷與知識影像的文化社群。',
    src: soul,
    logo: giloo,
    href: 'https://ooopenlab.cc/quiz/gb6eNKHnmmkJoBHMbYGH',
    features: ['轉換率高', '紀錄片線上看'],
    type: 'classic'
  },
  {
    title: 'justfont',
    desc: '新世代字型設計暨推廣教育團隊，致力於培育台灣下一代的設計力量。',
    src: justfont,
    logo: jf_logo,
    href: 'https://demo.ooopenlab.cc/justfont/',
    features: ['停留時間長', '字體金頭腦'],
    type: 'swiper'
  },
  // {
  //   title: '台北霞海城隍廟',
  //   desc: '台北頗負盛名的廟宇，祈求月老牽紅線的信徒絡繹不絕，歷年來促成無數對好姻緣。',
  //   src: flower,
  //   logo: flower_logo,
  //   href: 'https://superquiz.do/q/xiahai-flower/',
  //   features: ['測愛情觀', '特有種花籤詩'],
  //   type: 'swiper'
  // },
  {
    title: '上上芊食尚顧問',
    desc: '營養師顧問團隊，提供專業營養知識結合廚房料理美味實驗，打造健康生活風格。',
    src: bonne_chance,
    logo: bonne_chance_logo,
    href: 'https://ooopenlab.cc/quiz/WmcdFEWGXDSz15Lzt2FH',
    features: ['食尚達人', '懂吃懂營養'],
    type: 'swiper'
  }
].map((g) => ({
  ...g,
  moduleName: quizModules.find((m) => m.name === g.type)?.title
}));

export const Game = ({ game, width, mx, flex, compact, maxW, ...props }) => {
  const ele = (hovered) => (
    <Box
      transform={`scale(${hovered ? 1.02 : 0.95})`}
      transition="transform 0.5s"
      pos="relative"
      outline="1px solid black"
      {...props}
    >
      <Box bg={game.coverBg}>
        <RatioImage
          src={game.src}
          ratio={1180 / 618}
          alt={game.desc || game.title}
        />
      </Box>
      <Flex borderTop="1px solid black">
        <Box
          width="59%"
          bg="black"
          py="0.875rem"
          fontWeight={'bold'}
          fontSize={responsive('1.125em', '1.5em')}
          letterSpacing={responsive('0.125rem', '0.25rem')}
          color="white"
          textAlign={'center'}
          lineHeight={1}
        >
          {game.moduleName || fallbackNames[game.type]}
        </Box>
        <Box width="41%" bg="white">
          <Center height="100%">
            <Box width={'85%'}>
              <RatioImage
                src={play}
                ratio={406 / 67}
                alt={game.desc || game.title}
              />
            </Box>
          </Center>
        </Box>
      </Flex>
      <Box
        width={responsive(compact ? '3em' : '5em', null, null, '4.5em', '6em')}
        pos="absolute"
        top="0"
        left="0"
        transform="translate(-25%, -40%)"
      >
        <RatioImage src={medal} ratio={1} alt="Medal Shape" />
        <Box
          width="50%"
          pos="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <RatioImage src={game.logo} ratio={1} alt={game.title} />
        </Box>
      </Box>
      {!compact && (
        <>
          <HStack
            spacing={2}
            fontWeight="bold"
            fontSize={responsive('0.875em', '1em')}
            pos="absolute"
            right="0.5em"
            top="0"
            transform="translate3d(0,-60%,0)"
          >
            {game.features.map((text) => (
              <Box
                border="1px solid"
                px="0.375rem"
                py="0.25rem"
                bg="bgYellow"
                key={text}
              >
                #{text}
              </Box>
            ))}
          </HStack>
        </>
      )}
      <LinkOverlay
        href={game.href}
        target="_blank"
        rel="noopener"
        aria-label={game.title}
      />
    </Box>
  );
  const [hoverable] = useHover(ele);
  return (
    <Box
      mx={mx || responsive('auto', null, '2em')}
      px={responsive('1.5em', 0)}
      width={width}
      maxW={responsive('25em', 'auto')}
      flex={flex}
    >
      {hoverable}
    </Box>
  );
};

const Play = () => {
  return (
    <Box py={responsive('5.375em', '5.125em')}>
      <Flex
        mb="1em"
        pos="relative"
        justifyContent="center"
        alignItems="center"
        zIndex={6}
      >
        <Box
          flexGrow="0"
          flexShrink="0"
          flexBasis={[9, 12, null, null, '5.5rem']}
        >
          <svg viewBox="0 0 87.58 80.88">
            <use xlinkHref="/sprites/home.svg#redStarWithDot" />
          </svg>
        </Box>
        <TitleText
          mx={responsive('0.5rem', '1.5rem')}
          letterSpacing={responsive('0.027em', '3.6px')}
        >
          看看其他品牌怎麼玩
        </TitleText>
        <Box
          flexGrow="0"
          flexShrink="0"
          flexBasis={[9, 12, null, null, '5.5rem']}
        >
          <svg viewBox="0 0 84.92 80.88">
            <use xlinkHref="/sprites/home.svg#blueStarWithDot" />
          </svg>
        </Box>
      </Flex>
      <Carousel
        zIndex={6}
        px={responsive('1em', '10em')}
        sliderProps={{
          infinite: true,
          slidesToShow: 3,
          arrow: true,
          responsive: [
            {
              breakpoint: 1366,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 1,
                arrow: false
              }
            }
          ]
        }}
        dotProps={{
          transform: 'translate(-50%, 0%)'
        }}
        fontWeight="black"
        whiteSpace={responsive('pre-wrap', 'nowrap')}
        textAlign={responsive('center')}
        // static
      >
        {games.map((game, index) => (
          <Button
            variant="pure"
            trackerType="case_click"
            trackerName={`example${index + 1}`}
            key={index}
          >
            <Game
              game={game}
              my={['3.25em', null, null, '3.5em', null, '9em']}
            />
          </Button>
        ))}
      </Carousel>
      {/* <Box textAlign="center" mt={['4.5em', null, null, '3.5em', '2em']}>
        <Button variant="outline" py={["0.625rem", '1.25em']} px="2em" height="auto">
          <Text letterSpacing="0.25rem" fontWeight="bold" mt="-0.125em" fontSize={['1.125em', '2.25em']} lineHeight={1}>看更多測驗</Text>
          <svg viewBox="0 0 50 50" alt="下一頁">
            <use xlinkHref="/sprites/home.svg#mediumArrow" />
          </svg>
        </Button>
      </Box> */}
    </Box>
  );
};

export default Play;
