import Head from 'next/head';

const HeadMeta = ({ url, title, description, ogImage }) => {
  const schema = () => {
    return JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      name: process.env.NEXT_PUBLIC_APP_NAME,
      url: process.env.NEXT_PUBLIC_URL ?? process.env.NEXT_PUBLIC_VERCEL_URL
    });
  };

  return (
    <Head>
      {title && <title>{`${title} | ${process.env.NEXT_PUBLIC_APP_NAME}`}</title>}
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />
      <meta property="og:type" content="website" />
      <meta
        property="og:site_name"
        content={process.env.NEXT_PUBLIC_APP_NAME}
      />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={ogImage} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: schema() }}
      />
    </Head>
  );
};

export default HeadMeta;
