import { FEE_ADVANCED } from './fee';
import uploadGifCover from 'public/plans/locked/uploadGif.jpg';
import backgroundMusicCover from 'public/plans/locked/backgroundMusic.jpg';
import sameAsMeCover from 'public/plans/locked/sameAsMe.jpg';
import scoreDetailsCover from 'public/plans/locked/scoreDetails.gif';
import mailingListCover from 'public/plans/locked/mailingList.jpg';
import redirectResultCover from 'public/plans/locked/redirectResult.jpg';
import tagManagerCover from 'public/plans/locked/tagManager.jpg';

const images = {
  uploadGif: uploadGifCover,
  backgroundMusic: backgroundMusicCover,
  sameAsMe: sameAsMeCover,
  scoreDetails: scoreDetailsCover,
  mailingList: mailingListCover,
  redirectResult: redirectResultCover,
  tagManager: tagManagerCover
};

const advanced = FEE_ADVANCED.reduce((acc, category) => {
  category.addons.forEach(({ details, title, name: addonName }) => {
    details.forEach((detail) => {
      acc[detail.name] = {
        ...detail.unlock,
        cover: images?.[detail.name],
        hash: title,
        addonName,
        name: detail.name
      };
    });
  });
  return acc;
}, {});

export default advanced;
