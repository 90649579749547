const classic = {
  name: 'classic',
  title: '心理測驗',
  description:
    '人都對自己最有興趣，想知道自己會是哪一類或適合什麼，善用這個好奇與需求，提高觸及率或推薦商品吧！',
  multiQuestions: true,
  howto: {
    quote: '憑直覺回答問題，看看你的個人專屬測驗結果！',
    description:
      '在每一題的選項背後貼上不同結果的標籤＃，最後哪一個標籤＃數量最多，<br />填答者將被導向該結果'
  },
  scenario: [
    {
      title: '活動宣傳',
      description: '建立有話題的企劃，讓更多人主動分享，為你的活動曝光'
    },
    {
      title: '推薦商品',
      description: '從需求和偏好的了解出發，最後提供個人化的建議'
    },
    {
      title: '理念推廣',
      description: '進行不同情境選擇，再透過意想不到的結果使人反思'
    }
  ],
  price: 12800
};

const measure = {
  name: 'measure',
  title: '計分測驗',
  // isBeta: true,
  description:
    '從簡易的調查，到嚴謹的多題量表測驗，都可以透過計分量表的模組來達成！',
  multiQuestions: true,
  scoredResult: true,
  howto: {
    quote: '憑直覺根據自身狀況作答，完成後即可得到分數結果！',
    description:
      '以量表為測驗主體，根據得分高低計算最後的分數，\n可以設定級距來規劃不同的填答者看到的結果頁內容'
  },
  scenario: [
    {
      title: '知識傳播',
      description: '讓填答者意識到自己的狀態或程度，採取對應的建議行動'
    },
    {
      title: '學術研究',
      description: '結合理論模型的題庫或自行設計，得到嚴謹的調查結果'
    },
    {
      title: '活動反饋',
      description: '配合活動主視覺製作問卷，快速了解參加者的回饋'
    }
  ],
  price: 12800
};

const swiper = {
  name: 'swiper',
  altName: 'swipper',
  title: '得分挑戰',
  description:
    '人人都有挑戰自我、證明自己的本能，不論是和自己比還是與別人競爭，掌握成就感與好勝心，就能加強擴散！',
  scoredResult: true,
  howto: {
    quote: '向左滑、向右滑，哪個是正確答案？',
    description:
      '在設定好的秒數內快問快答，最後將按照得分呈現填答者的等級，<br />還可以展開檢視答對和答錯的題目'
  },
  scenario: [
    {
      title: '忠誠考驗',
      description: '出題考考你的粉絲有多暸解你的品牌、遊戲角色或產品服務'
    },
    {
      title: '知識傳播',
      description: '讓填答者意識到自己的認知落差，進而有動力補足相關知識'
    },
    {
      title: '教育展演',
      description: '在各種形式的學習前後，讓參加者測測看自己的程度如何'
    }
  ],
  price: 16800
};

const runway = {
  name: 'runway',
  // altName: 'dodger',
  title: '跑道閃避',
  description:
    '改造經典賽車遊戲，結合刺激的速度感與客製視覺的新鮮感，再搭配充滿節奏感的背景音樂，讓人想一玩再玩！',
  hasDefaultResult: true,
  defaultResultTitle: '全閃避結果',
  howto: {
    quote: '在漸漸加快的速度中往前移動，左右移動避開各種障礙物吧！',
    description:
      '客製跑道背景、遊戲主角與路上的障礙物，<br />最後將呈現挑戰者敗在哪個障礙物、撐了多長時間'
  },
  isBeta: true,

  scenario: [
    {
      title: '直覺互動',
      description: '不需要任何門檻，娛樂性高且適合各種年齡層參與'
    },
    {
      title: '挑戰兌獎',
      description: '融入各式線上線下活動，讓挑戰成功者抽獎或兌獎'
    },
    {
      title: '議題溝通',
      description: '結合挑戰形式與特定議題，加強劇情深度與體驗'
    }
  ],
  price: 18800
};

const catcher = {
  name: 'catcher',
  // altName: 'dodger',
  title: '接球挑戰',
  description:
    '連六歲小孩都會玩，寓教於樂或是結合情境直覺挑戰，都能讓各種年齡層的粉絲玩得開心！',
  scoredResult: true,
  isBeta: true,
  howto: {
    quote: '接下會加分的東西、閃避接了會扣分的東西！',
    description:
      '配合你想溝通的主題，設定好哪些物件是加分、哪些會扣分，看時間內挑戰者能得幾分'
  },
  scenario: [
    {
      title: '直覺互動',
      description: '不需要任何門檻，娛樂性高且適合各種年齡層參與'
    },
    {
      title: '挑戰兌獎',
      description: '融入各式線上線下活動，讓挑戰成功者抽獎或兌獎'
    },
    {
      title: '知識傳播',
      description: '讓填答者意識到自己的認知落差，進而有動力補足相關知識'
    }
  ],
  price: 12800
};

const survey = {
  name: 'survey',
  title: '問卷一頁一題',
  homeTitle: '問卷調查',
  description:
    '製作一份簡易流暢又具有視覺吸引力的問卷，一定可以提高填答的完成度，甚至有機會在最後提高轉換的意願！',
  multiQuestions: true,
  surveyQuestions: true,
  term: '問卷',
  verb: '填',
  singleResult: true,
  howto: {
    quote: '運用各式題型，快速瞭解受眾的想法！',
    description:
      '根據調查需求設計「一頁一題」或「一頁多題」的問卷，\n並搭配更彈性的客製視覺呈現出獨特的品牌或產品魅力'
  },
  scenario: [
    {
      title: '商業調查',
      description: '從產品開發到預購，貼近使用者需求，並搜集潛在消費名單'
    },
    {
      title: '學術研究',
      description: '靈活的視覺和題型，方便融入各種研究情境'
    },
    {
      title: '活動邀請',
      description: '配合活動主視覺製作問卷，加強主題與情境感'
    }
  ],
  price: 3600
};

const multiSurvey = {
  ...survey,
  title: '問卷一頁多題',
  mods: {
    allowMultiQuestions: true
  }
};

const multiFactor = {
  name: 'multifactor',
  title: '多維度測驗',
  description:
    '透過更多維度的設定，讓心理測驗的分析結果更精準，或使商品推薦更貼近消費者的需求！',
  multiQuestions: true,
  multiFactor: true,
  // isBeta: true,
  howto: {
    quote: '透過多維度讓分析結果更精準！',
    description:
      '進階版心理測驗，可設定多維度標籤，\n系統將自動為你運算出結果的數量讓你編輯'
  },
  scenario: [
    {
      title: '活動宣傳',
      description: '建立有話題的企劃，讓更多人主動分享，為你的活動曝光'
    },
    {
      title: '推薦商品',
      description: '從需求和偏好的了解出發，提供更精準的個人化建議'
    },
    {
      title: '學術研究',
      description: '支持多維度的設定，使測驗更符合想導入理論的研究架構'
    }
  ],
  price: 16800
};

const canvas = {
  name: 'canvas',
  title: '客製圖產生器',
  description:
    '10 秒鐘完成客製大頭照、賀卡、貼圖或訂製商品的圖片，讓創作者更有參與感與成就感！',
  canvasQuestions: true,
  singleResult: true,
  howto: {
    quote: '在每個圖層選擇自己喜歡的視覺元素，一鍵完成客製圖片！',
    description:
      '上傳自己創作好的圖層，<br />再決定好圖層的上下排序與背景設定就完成了'
  },
  scenario: [
    {
      title: '理念推廣',
      description: '呼籲替換主題視覺以響應特定議題＆表達支持'
    },
    {
      title: '節慶互動',
      description: '讓品牌愛好者使用自家元素，客製賀卡或貼圖'
    },
    {
      title: '訂製商品',
      description: '在訂製前模擬看看視覺效果，再確認需求下單'
    }
  ],
  price: 16800
};

const quizModules = [
  classic,
  measure,
  swiper,
  survey,
  multiSurvey,
  runway,
  catcher,
  multiFactor,
  canvas
];

export default quizModules;
