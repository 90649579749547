import React, { useContext } from 'react';
import { Box, Flex, HStack, IconButton, Text } from '@chakra-ui/react';

import Button from 'components/Button';
import SVG from '../components/SVG';
import Link from '../components/Link';
import Footer from '../components/footer/Home';
import { Media, responsive } from '../contexts/responsive';

import contactContext from 'contexts/contact/context';
import { BUSINESS, EDUCATION } from 'contexts/contact/needs';
import { usePlausible } from 'next-plausible';

const BusinessSub = (props) => (
  <SVG viewBox="0 0 350 350" {...props}>
    <title id="badgeBusinessTitle">商業合作</title>
    <use xlinkHref="/sprites/home.svg#badgeBusinessSub" />
  </SVG>
);

const EducationSub = (props) => (
  <SVG viewBox="0 0 350 350" {...props}>
    <title id="badgeEducationTitle">教育合作</title>
    <use xlinkHref="/sprites/home.svg#badgeEducationSub" />
  </SVG>
);

const SVGIconButton = ({ trackerName, ...props }) => {
  const plausible = usePlausible();
  return (
    <Button
      as={IconButton}
      variant="unstyled"
      rounded="full"
      width="full"
      height="auto"
      _hover={{
        color: 'white',
        bg: 'black'
      }}
      minW="auto"
      lineHeight={0}
      onClick={(e) => {
        props.onClick(e);
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'contact_click', {
            button_name: `contact_${trackerName}`
          });
        }

        plausible('clickButton', {
          props: {
            buttonName: `contact_${trackerName}`
          }
        });
      }}
      {...props}
    />
  );
};

const About = ({ noFooter, fullpageApi }) => {
  const { openForm } = useContext(contactContext);
  return (
    <Box pos="relative" pt={responsive('2.875em', '6em')}>
      <Box px={responsive('2.25em', 0)} mb={responsive('0.75em', '1.25em')}>
        <Flex flexDirection={responsive('row', 'column')}>
          <Box width={responsive('1.5em', '37.5%')} mx="auto">
            <Media greaterThanOrEqual="md">
              <svg viewBox="0 0 743 60" alt="Who are We?">
                <use xlinkHref="/sprites/home.svg#aboutTitleWide" />
              </svg>
            </Media>
            <Media lessThan="md">
              <svg viewBox="0 0 25 290" alt="Who are We?">
                <use xlinkHref="/sprites/home.svg#aboutTitle" />
              </svg>
            </Media>
          </Box>
          <Box
            width={responsive('75%', '37.5%')}
            pos="relative"
            zIndex="1"
            mt={responsive(0, '1.5em')}
            mx="auto"
            fontSize={responsive('0.875em', '1.35vw')}
            lineHeight={1.75}
            letterSpacing="2px"
            textAlign="justify"
          >
            <Text>
              這是
              <Link
                fontWeight="bold"
                textDecoration="underline"
                isExternal
                href="https://relab.cc"
              >
                RE:LAB 資訊設計顧問公司
              </Link>
              宇宙中的新品牌。
            </Text>
            <Text>
              超開放實驗室開放讓品牌用低門檻的資源製作有趣的互動模組，同時也歡迎教育應用的合作！另外，我們也使用超開放實驗室的模組工具發展了
              <Link
                fontWeight="bold"
                textDecoration="underline"
                isExternal
                href="https://superquiz.do"
              >
                超測驗
              </Link>
              計畫，不定期將實用的心理學理論設計成測驗，讓人能在思考中不斷超越自己。
            </Text>
            <Media lessThan="md">
              <HStack justifyContent="center" mt={'0.5em'}>
                <SVGIconButton
                  width="40%"
                  icon={<BusinessSub />}
                  onClick={() => openForm(BUSINESS.label)}
                  trackerName={BUSINESS.name}
                  aria-labelledby="badgeBusinessTitle"
                />
                <SVGIconButton
                  width="40%"
                  icon={<EducationSub />}
                  onClick={() => openForm(EDUCATION.label)}
                  trackerName={EDUCATION.label}
                  aria-labelledby="badgeEducationTitle"
                />
              </HStack>
            </Media>
          </Box>
        </Flex>
        <HStack
          as={Media}
          spacing={8}
          greaterThanOrEqual="md"
          width="100%"
          mt={'1.5em'}
          pos="relative"
          zIndex="1"
          justifyContent="center"
        >
          <SVGIconButton
            width="9%"
            icon={<BusinessSub />}
            onClick={() => openForm(BUSINESS.label)}
            trackerName={BUSINESS.name}
            aria-labelledby="badgeBusinessTitle"
          />
          <SVGIconButton
            width="9%"
            icon={<EducationSub />}
            onClick={() => openForm(EDUCATION.label)}
            trackerName={EDUCATION.label}
            aria-labelledby="badgeEducationTitle"
          />
        </HStack>
        <Box
          width="100%"
          as={Media}
          lessThan="md"
          opacity="0"
          visibility="hidden"
        >
          <svg viewBox="0 0 288.48 101.07" alt="">
            <use xlinkHref="/sprites/home.svg#aboutDeco" />
          </svg>
        </Box>
      </Box>
      {
        <Box pos="relative">
          <Box pos="absolute" left="0" right="0" bottom="100%">
            <Box width="100%" as={Media} lessThan="md" px={['2.75em', 0]}>
              <svg viewBox="0 0 288.48 101.07" alt="">
                <use xlinkHref="/sprites/home.svg#aboutDeco" />
              </svg>
            </Box>
            <Box as={Media} greaterThanOrEqual="md" pointerEvents="none">
              <svg viewBox="0 0 1917.97 556.61" alt="">
                <use xlinkHref="/sprites/home.svg#aboutDecoWide" />
              </svg>
            </Box>
          </Box>
          <Footer />
        </Box>
      }
    </Box>
  );
};

export default About;
