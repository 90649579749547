import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import range from 'lodash/range';
import { Box, Flex, HStack } from '@chakra-ui/react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Chevron from './Chevron';
import Button from './Button';
import { responsive } from '../contexts/responsive';
// import { usePlausible } from 'next-plausible';

const StyleBox = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'first' &&
    prop !== 'isLast' &&
    prop !== 'static' &&
    prop !== 'isPlan'
})`
  img,
  picture {
    display: block;
  }
  .slick-track {
    display: ${(props) => props.isPlan && 'flex'};
  }
  .slick-slide {
    height: ${(props) => props.isPlan && 'auto'};
  }
  .slick-slide > div {
    height: ${(props) => props.isPlan && '100%'};
  }
  .slick-arrow {
    height: auto;
    font-size: 0.75em;
    z-index: 1;
    &::before {
      content: none;
    }
  }
  .slick-prev {
    width: 3.125vw;
    height: 3.125vw;
    left: ${(props) => (props.static ? '1.5em' : '-5%')};
  }
  .slick-next {
    width: 3.125vw;
    height: 3.125vw;
    right: ${(props) => (props.static ? '1.5em' : '-5%')};
  }
  @media screen and (max-width: 768px) {
    .slick-arrow {
      // display: ${(props) => (props.static ? 'block' : 'none !important')};
    }
    .slick-next,
    .slick-prev {
      width: 2.5em;
      height: 2.5em;
      transform: translateY(1em);
    }
    .slick-prev {
      left: ${(props) => (props.static ? '2%' : '-2.5%')};
    }
    .slick-next {
      right: ${(props) => (props.static ? '2%' : '-2.5%')};
    }
  }
`;

const Carousel = ({
  children,
  nodot,
  width,
  autoplay,
  arrows,
  slidesToShow,
  species,
  sliderProps,
  initialSlide = 0,
  dotMx,
  dotProps,
  isPlan,
  active = 0,
  trackerType,
  trackerName,
  showDot,
  setActive = () => {},
  ...props
}) => {
  const [activeItemIndex, setActiveIndex] = useState(initialSlide);
  const slickRef = useRef();
  useEffect(() => {
    if (slickRef.current) {
      slickRef.current.slickGoTo(activeItemIndex);
    }
  }, [slickRef, activeItemIndex]);
  useEffect(() => {
    setActiveIndex(0);
  }, [species]);
  useEffect(() => {
    setActive(activeItemIndex);
  }, [activeItemIndex]);
  useEffect(() => {
    if (typeof active !== 'undefined') {
      setActiveIndex(active);
    }
  }, [active]);
  const isLast = activeItemIndex + 1 === children.length;
  // const plausible = usePlausible();
  return (
    <StyleBox
      position="relative"
      width="100%"
      isLast={isLast}
      isPlan={isPlan}
      first={!activeItemIndex}
      {...props}
    >
      <Slider
        initialSlide={initialSlide}
        infinite={false}
        autoplay={autoplay}
        prevArrow={
          <Chevron.Left
            color="black"
            // onClick={() => {
            //   if (trackerName) {
            //     if (typeof window.gtag === 'function') {
            //       window.gtag('event', trackerType, {
            //         button_name: trackerName
            //       });
            //     }

            //     plausible('clickButton', {
            //       props: {
            //         buttonName: trackerName
            //       }
            //     });
            //   }
            // }}
            _hover={{ color: 'white', backgroundColor: 'black' }}
          />
        }
        nextArrow={
          <Chevron.Right
            color="black"
            _hover={{ color: 'white', backgroundColor: 'black' }}
          />
        }
        arrows={arrows}
        dots={false}
        ref={slickRef}
        autoplaySpeed={2500}
        speed={750}
        beforeChange={(from, to) => setActiveIndex(to)}
        {...sliderProps}
      >
        {children}
      </Slider>
      {!nodot && (
        <HStack
          position="absolute"
          bottom="0"
          left="50%"
          display={responsive('flex', children.length > 1 ? 'flex' : 'none')}
          transform="translate(-50%, 100%)"
          spacing={{
            base: 4
          }}
          {...dotProps}
        >
          {range(children.length).map((index) => (
            <Box key={index}>
              <Button
                border="1.5px solid"
                borderColor="black"
                bg={index === activeItemIndex ? 'black' : 'white'}
                transform={index === activeItemIndex && 'scale(1.5)'}
                minWidth="auto"
                height="auto"
                _hover={{
                  bg: 'black'
                }}
                trackerType={trackerType}
                trackerName={trackerName && `${trackerName}${index + 1}`}
                py={responsive('0.375rem', '0.5rem')}
                px={responsive('0.375rem', '0.5rem')}
                onClick={() => setActiveIndex(index)}
                aria-label={`第 ${index + 1} 頁`}
              />
            </Box>
          ))}
        </HStack>
      )}
    </StyleBox>
  );
};

Carousel.defaultProps = {
  arrows: true
};

export default Carousel;
