import { Text } from '@chakra-ui/react'
import { responsive } from 'contexts/responsive'
import React from 'react'

const TitleText = ({ children, ...props }) => {
  return (
    <Text textAlign="center" fontSize={responsive('1.75em', '3.125vw')} fontWeight="black" {...props}>{children}</Text>
  )
}

TitleText.Num = (props) => <TitleText as="span" fontSize={responsive('2.75rem', '5vw')} {...props} />

export default TitleText
